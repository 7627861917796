import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Official Guide to Polis Methods v1`}</h1>
    <p>{`Welcome to Polis the book!`}</p>
    <h2>{`Table of Contents`}</h2>
    <p>{`Prologue`}<br parentName="p"></br>{`
`}{`About the Authors`}<br parentName="p"></br>{`
`}{`Principles`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/polis/book/introduction"
      }}>{`Introduction`}</a>{`   `}</p>
    <ul>
      <li parentName="ul">{`Provocation: Is Polis too easy to use? `}</li>
      <li parentName="ul">{`What is Polis and what does it do?`}</li>
      <li parentName="ul">{`What has polis been successfully used for? `}
        <ul parentName="li">
          <li parentName="ul">{`vTaiwan`}</li>
          <li parentName="ul">{`The Klimarat in Austria`}</li>
          <li parentName="ul">{`The National University of Uruguay and the 2021 national referendum`}</li>
          <li parentName="ul">{`Youth Conversations in Pakistan, Bhutan, Timor Leste`}</li>
          <li parentName="ul">{`New Zealand's HiveMind`}</li>
          <li parentName="ul">{`Philippines builds on Taiwan model`}</li>
          <li parentName="ul">{`Townhall meeting in Kentucky turns tables on polarization`}</li>
          <li parentName="ul">{`DEMOS pairs Polis with nationwide representative sampling`}</li>
          <li parentName="ul">{`National Youth Council in Singapore`}</li>
          <li parentName="ul">{`Germany's Aufstehen political party crowdsources platform`}</li>
        </ul>
      </li>
      <li parentName="ul">{`What won’t Polis do?`}</li>
    </ul>
    <p>{`Me and my use case: is Polis the right fit?`}<br parentName="p"></br>{`
`}{`Polis as a step in a process`}<br parentName="p"></br>{`
`}{`Connecting in-person dialogue with online interaction`}<br parentName="p"></br>{`
`}{`Moving online groups in & out of Polis`}<br parentName="p"></br>{`
`}{`Planning your Engagement`}<br parentName="p"></br>{`
`}{`Communications 🤝 Branding`}<br parentName="p"></br>{`
`}{`Framing the topic`}<br parentName="p"></br>{`
`}{`Outreach 🤝 Distribution`}<br parentName="p"></br>{`
`}{`Phasing`}<br parentName="p"></br>{`
`}{`Moderation`}<br parentName="p"></br>{`
`}{`Report Interpretation`}<br parentName="p"></br>{`
`}{`Repeating the process`}<br parentName="p"></br>{`
`}{`What about the math?`}<br parentName="p"></br>{`
`}{`Values and the danger zone`}<br parentName="p"></br>{`
`}{`Existing Guides created by partners`}<br parentName="p"></br>{`
`}{`About the Computational Democracy Project`}<br parentName="p"></br>{`
`}{`Glossary`}<br parentName="p"></br>{`
`}{`Epilogue  `}</p>
    <hr></hr>
    <p>{`NOTES
How do we do relative links? `}</p>
    <ul>
      <li parentName="ul">{`How github does it: `}<a parentName="li" {...{
          "href": "https://github.blog/2013-01-31-relative-links-in-markup-files/"
        }}>{`https://github.blog/2013-01-31-relative-links-in-markup-files/`}</a></li>
      <li parentName="ul">{`How Gatsby (this site) does it: `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/plugins/gatsby-plugin-catch-links/"
        }}>{`https://www.gatsbyjs.com/plugins/gatsby-plugin-catch-links/`}</a>{` `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      